<div class="user-problems">
  <div class="registration-title">How can we help you?</div>
  <div class="row registration-sub-title company-type-sub-title">
    Please check all that apply to help us customize your onboarding experience
  </div>
  <div class="problem-container">
    @for (problem of problems(); track $index) {
      <div
        class="type-item"
        [ngClass]="{
          'type-selected': selectedProblems[problem.userProblemId]?.selected
        }"
        (click)="onCompanyBuyerChanged($event, problem.userProblemId)"
      >
        <div class="problem">
          {{ problem.description || problem.name }}
          <div class="type-item-check">
            <p-checkbox
              [ngModel]="selectedProblems[problem.userProblemId]?.selected"
              [binary]="true"
              [readonly]="true"
              #checkbox
            ></p-checkbox>
          </div>
        </div>
        @if (
          selectedProblems[problem.userProblemId]?.selected &&
          !problem.description
        ) {
          <textarea
            (click)="$event.stopPropagation()"
            [(ngModel)]="selectedProblems[problem.userProblemId].text"
            (ngModelChange)="textChange()"
            pInputTextarea
            placeholder="Tell us how we can help you in your role"
          ></textarea>
        }
      </div>
    }
  </div>
</div>

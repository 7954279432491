import { Inject, Injectable } from '@angular/core';
import { Tokens } from '@okta/okta-auth-js';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  from,
  of,
  timer,
} from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import {
  IdentityApi,
  OktaService,
} from 'sustainment-component';
import { UserAccount, UserSession } from 'sustainment-models';
import { environment } from 'src/environments/environment';
import { RefreshToken } from '@okta/okta-auth-js/types/lib/oidc/types/Token';
import { TokenManagerInterface } from '@okta/okta-auth-js/types/lib/oidc/types/TokenManager';
import { NavigationEnd, Router } from '@angular/router';
import { UserAccountAction } from '../store/userAccount/user-account.action';
import { UserAccountQuery } from '../store/userAccount/user-account.query';

declare let gtag: (
  event: 'event',
  action: 'user_login',
  what: { eventCategory: 'general'; eventLabel: 'successful_login'; value: 1 }
) => void;

const KEY_ROOT = `sustainment-${environment.appName}-${
  environment.production ? '' : 'non-prod-'
}`;
const KEY_LAST_ACTIVITY = `${KEY_ROOT}last-activity`; // last time the user did something in ms
const KEY_TOKEN_EXPIRATION = `${KEY_ROOT}token-expiration`; // length of time until the token expires in ms

const TEST_TIMEOUT_WARNING = undefined; // set to value for testing purposes (30 * 1000 for 30 seconds)
const TEST_TOKEN_EXPIRATION = undefined; // set to value for testing purposes (1 * 60 * 1000 for 1 minute)

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;
  public loadingSession$: Observable<boolean>;
  public tokenExpiringSoon$: Observable<boolean>;

  private _isAuthenticated = new BehaviorSubject<boolean>(false);
  private _loadingSession = new BehaviorSubject<boolean>(true);
  private _tokenExpiringSoon = new BehaviorSubject<boolean>(false);

  private _refreshTimerSubscription: Subscription;
  private _callbackCode: string;

  public get timeoutWarning(): number {
    return TEST_TIMEOUT_WARNING ?? environment.timeoutWarning;
  }

  public constructor(
    private _identityApi: IdentityApi,
    private _userAccountAction: UserAccountAction,
    private _oktaService: OktaService,
    private _router: Router,
    @Inject('UserAccountQuery') private _userAccountQuery: UserAccountQuery
  ) {
    // check if there is a callback route and code parameter
    if (window.location.pathname === '/login/callback') {
      const params = new URLSearchParams(window.location.search);
      this._callbackCode = params.get('code') || '';
    }
    // store last activity in local storage so it can be shared between tabs/windows (could also attach to interceptors)
    _router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe({
        next: (end: NavigationEnd) => {
          if (!end.urlAfterRedirects.includes('/login')) {
            this.updateLastActivity();
          }
        },
      });

    this.loadingSession$ = this._loadingSession.asObservable();
    this.tokenExpiringSoon$ = this._tokenExpiringSoon.asObservable();
    this.isAuthenticated$ = from(
      this._oktaService.widget.authClient.isAuthenticated()
    ).pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          // may need to show the login form
          this._loadingSession.next(false);
        } else {
          this._loadingSession.next(true);
        }

        return this._isAuthenticated.pipe(
          first(),
          switchMap((sustainmentAuthenticated) => {
            if (sustainmentAuthenticated) {
              this._loadingSession.next(false);
              return of(true);
            }

            return this.restoreSession();
          })
        );
      })
    );
  }

  private get tokenManager(): TokenManagerInterface {
    return this._oktaService.widget.authClient.tokenManager;
  }

  public login(
    oktaTokens: Tokens,
    isCallback?: boolean
  ): Promise<boolean> {
    this._oktaService.widget.authClient.tokenManager.setTokens(oktaTokens);

    // this next line is where the screen refresh happens in Portal only
    return from(this._oktaService.widget.authClient.token.getUserInfo())
      .pipe(
        switchMap((user) =>
          this._identityApi.getUserAccount({
            username: user.name!,
            firstName: user.given_name!,
            lastName: user.family_name!,
          })
        ),
        switchMap((userAccount: UserAccount) => {
          this._userAccountAction.setUserAccount(userAccount);
          const org = userAccount.organizations[0];
          return (userAccount.organizations[0] as any).pending
            ? EMPTY
            : this._identityApi.login(org.sustainmentId, false, isCallback);
        })
      )
      .toPromise()
      .then((userSession: UserSession | undefined) => {
        if (!userSession) return false;
        
        if (environment.production) {
          gtag('event', 'user_login', {
            eventCategory: 'general',
            eventLabel: 'successful_login',
            value: 1,
          });
        }
        this._userAccountAction.setUserSession(userSession);
        this.startTokenRefreshInterval(userSession.appKeyExpirationUtc);
        this._isAuthenticated.next(true);
        this._loadingSession.next(false);
        return true;
      })
      .catch(() => false);
  }

  public stopTokenRefreshInterval(): void {
    if (this._refreshTimerSubscription) {
      this._refreshTimerSubscription.unsubscribe();
    }
  }

  public logout(): void {
    this._identityApi
      .logout()
      .pipe(
        catchError(() => of(null)),
        switchMap(() =>
          this._oktaService.widget.authClient.signOut({
            postLogoutRedirectUri: window.location.origin + '/login',
          })
        )
      )
      .subscribe(() => {
        this.closeSession();
      });
  }

  private isRefreshTokenValid(
    refreshToken: RefreshToken | undefined | null
  ): boolean {
    return (
      (refreshToken && !this.tokenManager.hasExpired(refreshToken)) || false
    );
  }

  public restoreSession(): Promise<boolean> {
    this._loadingSession.next(true);

    return this.tokenManager.getTokens().then((tokens: Tokens) => {
      if (this.isRefreshTokenValid(tokens.refreshToken) || this._callbackCode) {
        const isCallback = (this._callbackCode || '').length > 0;

        // in the case a user logs in, then exits their browser as the token expires
        // we should not refresh their token even though the okta refresh token is valid
        if (!isCallback && !this.hasActivityHappenedSinceLastTokenRefresh()) {
          this.closeSession();
          return Promise.resolve(false);
        }

        // log back in
        return this._oktaService.widget.authClient.token
          .renewTokens()
          .then((refreshed: Tokens) => this.login(refreshed, isCallback))
          .catch(() => {
            // can happen if logged into a different app and the user does not have access to this one
            this.closeSession();
            return false;
          });
      } else {
        this._loadingSession.next(false);
        return Promise.resolve(false);
      }
    });
  }

  private getTimeUntilExpiration(expirationTime: Date): number {
    return (
      TEST_TOKEN_EXPIRATION ??
      new Date(expirationTime).getTime() - new Date().getTime()
    );
  }

  private startTokenRefreshInterval(expirationTime: Date): void {
    this.stopTokenRefreshInterval();
    const timeUntilExpiry = this.getTimeUntilExpiration(expirationTime);

    // store how long tokens are good for so we can refresh them before they expire if needed
    localStorage.setItem(KEY_TOKEN_EXPIRATION, timeUntilExpiry.toString());

    const refreshInterval = timeUntilExpiry - this.timeoutWarning;
    this._refreshTimerSubscription = timer(refreshInterval).subscribe(() => {
      if (
        this.hasActivityHappenedSinceLastTokenRefresh(
          this.timeoutWarning + 5000
        )
      ) {
        this.restoreSession();
      } else {
        this._tokenExpiringSoon.next(true);
        this.stopTokenRefreshInterval();
      }
    });
  }
  
  public closeSession(): void {
    this._loadingSession.next(false);
    this._oktaService.widget.authClient.tokenManager.removeRefreshToken();
    this.stopTokenRefreshInterval();
    this._userAccountAction.removeUserSession();
    this._isAuthenticated.next(false);
    localStorage.removeItem(KEY_TOKEN_EXPIRATION);
    localStorage.removeItem(KEY_LAST_ACTIVITY);
  }

  public updateLastActivity(): void {
    localStorage.setItem(KEY_LAST_ACTIVITY, new Date().getTime().toString());
  }

  private getLastActivity(): number | null {
    const lastActivity = localStorage.getItem(KEY_LAST_ACTIVITY);
    return lastActivity ? parseInt(lastActivity, 10) : null;
  }

  private hasActivityHappenedSinceLastTokenRefresh(buffer?: number): boolean {
    const lastActivity = this.getLastActivity();
    const timeUntilExpiry = this.getTokenExpiration();
    if (!lastActivity) {
      return false;
    }

    const now = new Date().getTime();
    return lastActivity + timeUntilExpiry > now + (buffer || 0);
  }

  private getTokenExpiration(): number {
    const expiration = localStorage.getItem(KEY_TOKEN_EXPIRATION);
    return expiration ? parseInt(expiration, 10) : 60 * 1000 * 15; // default to 15 minutes
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationContainerComponent } from 'src/app/containers/registration/registration.container';

const routes: Routes = [
  { path: '', component: RegistrationContainerComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}

import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { map } from 'rxjs/operators';
import {
  IVendorLocation,
  IVendorOnboardingResult,
  IVendorOnboardingSave
} from 'src/app/store/vendor/vendor.model';
import { LoggerDatadogService, UserRole } from 'sustainment-component';
import {
  IGeocodeFeature,
  ICapabilitytProcess,
  ICapability,
  SupplierListModel
} from 'sustainment-models';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as ManufacturingHelper from 'sustainment-component';
import { OnboardingAPI } from 'src/app/api/onboarding.api';
import { CapabilityQuery } from 'src/app/store/capability/capability.query';
import { Title } from '@angular/platform-browser';
import { regex } from 'src/app/regex/regex';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';

enum RegistrationSteps {
  CompanyDetails,
  CompanySuggestions,
  CompanyTypeRegistration,
  UserProblems,
  WelcomeScreen
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() public vendorInfo: IVendorLocation;
  @Input() public processes: ICapabilitytProcess[];
  @Input() public industries: ICapability[];
  @Input() public autoCompleteOnboarding = false;
  public userRoles = input.required<UserRole[]>();

  @Output() public logout = new EventEmitter<void>();
  @Output() public patchVendor = new EventEmitter<{
    vendor: IVendorOnboardingResult;
    roles: number[];
    problems: {
      id: number;
      text: string;
    }[];
  }>();
  @Output() public vendorUpdated = new EventEmitter<IVendorOnboardingSave>();
  @Output() public requestedToJoin = new EventEmitter<SupplierListModel>();

  public vendorSelected: SupplierListModel | null;
  public form: UntypedFormGroup;
  public disableContinue = true;
  public loadCompaniesSuggestion = false;

  public suggestedVendors: SupplierListModel[] = [];

  public steps = RegistrationSteps;
  public currentStep = this.steps.CompanyDetails;
  public selectedProcesses: any[] = [];
  public selectedIndustries: any[] = [];
  public isInternationalCompany: boolean;
  public internationalAcknowledgmentAccepted: boolean;
  public selectedProblems: ManufacturingHelper.UserProblem[] = [];

  public roles: number[] = [];
  public problems: { id: number; text: string }[];
  private _capabilities: any;

  public constructor(
    private formBuilder: UntypedFormBuilder,
    public loggerDatadogService: LoggerDatadogService,
    private onboardingApi: OnboardingAPI,
    private _capabilityQuery: CapabilityQuery,
    private titleService: Title,
    private _userAccountQuery: UserAccountQuery
  ) {
    this.titleService.setTitle('Set up your account - Sustainment Portal');
  }

  public ngOnInit(): void {
    this.setForm();
    this._capabilityQuery.select().subscribe((capabilities) => {
      this._capabilities = capabilities;
    });

    // for invited & preloaded vendors
    if (this.vendorInfo?.processes) {
      this.selectedProcesses = this.vendorInfo.processes.map((process: any) => {
        return {
          code: process?.code,
          name: process?.name,
          isDefault: true,
          isNew: false
        };
      });
    }

    if (this.vendorInfo?.industries) {
      this.selectedIndustries = this.vendorInfo.industries.map(
        (industry: any) => {
          return industry?.name;
        }
      );
    }

    // if the vendor is preloaded, move to the company type registration step
    if (this.autoCompleteOnboarding) {
      this.currentStep = this.steps.CompanyTypeRegistration;
    }
  }

  public setForm(): void {
    this.form = this.formBuilder.group({
      name: [
        this.vendorInfo?.name || null,
        [Validators.maxLength(75), Validators.required]
      ],
      location: [this.vendorInfo?.location || null, [Validators.required]],
      website: [
        this.vendorInfo?.website || null,
        [Validators.pattern(regex.websiteUrlValidatorPattern)]
      ]
    });
  }

  public onClickBack(): void {
    if (this.currentStep === this.steps.UserProblems) {
      this.disableContinue = true;
    }
    if (this.currentStep !== this.steps.CompanyTypeRegistration) {
      this.currentStep -= 1;
    } else {
      this.currentStep =
        this.suggestedVendors.length > 0
          ? this.steps.CompanySuggestions
          : this.steps.CompanyDetails;
    }
    this.vendorUpdated.emit({
      ...this.form.value,
      processes: [],
      industries: []
    });
  }

  public onContinueClick(): void {
    this.disableContinue = true;
    switch (this.currentStep) {
      case this.steps.CompanyDetails: {
        this.vendorUpdated.emit({
          ...this.form.value,
          processes: [],
          industries: []
        });

        const country = this.getCountry();

        this.loadCompaniesSuggestion = true;
        this.vendorInfo.name = this.form.value.name;
        this.onboardingApi
          .getOpensearchResults({
            companyName: this.form.value.name,
            state: country == 'US' ? this.getContextText('region') : '',
            website: this.getWebsiteDomain(this.form.value.website),
            country: country == 'US' ? '' : country
          })
          .pipe(map((x) => x))
          .subscribe((results) => {
            if (results) {
              results.forEach((vendor) => {
                if (vendor && vendor && vendor.processes)
                  vendor.processes =
                    ManufacturingHelper.mapVendorProfileProcessSubprocess(
                      vendor,
                      this.processes
                    );
              });

              this.suggestedVendors = results;
              this.currentStep =
                this.suggestedVendors.length > 0
                  ? this.steps.CompanySuggestions
                  : this.steps.CompanyTypeRegistration;
              this.logVendorStep();

              if (this.suggestedVendors.length === 0) {
                this.onNoneClicked();
              }
              this.loadCompaniesSuggestion = false;
            }
          });
        break;
      }
      case this.steps.CompanySuggestions:
        if (this.vendorSelected?.isRegistered) {
          this.requestedToJoin.emit(this.vendorSelected);
        } else {
          this.currentStep = this.steps.CompanyTypeRegistration;
        }
        break;

      case this.steps.CompanyTypeRegistration:
        this.selectedProblems = this.userRoles()
          .filter((x) => this.roles.includes(x.userRoleId))
          .reduce((acc: ManufacturingHelper.UserProblem[], x) => {
            x.problems.forEach((problem) => {
              if (!acc.some((p) => p.userProblemId === problem.userProblemId)) {
                acc.push(problem);
              }
            });
            return acc;
          }, [])
          .sort((a, b) => a.userProblemId - b.userProblemId);
        if (this.selectedProblems.length)
          this.currentStep = this.steps.UserProblems;
        else this.saveVendor();
        break;
      case this.steps.UserProblems:
        this.saveVendor();
        break;
      default:
        break;
    }
  }

  private getWebsiteDomain(website: string): string {
    if (!website) return '';

    website = website.replace(/\s+/g, '');

    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      website = 'http://' + website;
    }

    const parsedUrl = new URL(website);
    return parsedUrl.host;
  }

  public enableContinue(event: SupplierListModel | boolean): void {
    this.disableContinue = !event;

    if (
      this.isInternationalCompany &&
      !this.internationalAcknowledgmentAccepted
    )
      this.disableContinue = true;

    if (typeof event !== 'boolean' && event?.sustainmentId) {
      this.vendorSelected = event;
      this.disableContinue = this.vendorSelected.isSelected ? false : true;
    }
  }

  public onNoneClicked(): void {
    this.vendorSelected = null;
    this.currentStep = this.steps.CompanyTypeRegistration;
  }

  public saveVendor(): void {
    this.disableContinue = true;

    this.vendorUpdated.emit({
      ...this.form.value
    });

    const location: IGeocodeFeature = this.form.value.location;
    const name: string = this.vendorSelected?.name || this.form.value.name;
    const website: string =
      this.vendorSelected?.website || this.form.value.website;

    const country = this.getCountry();

    const vendor: IVendorOnboardingResult = {
      name,
      //TODO: Missing Unit
      primaryAddress: {
        address:
          this.vendorSelected?.address?.addressLine ||
          location.place_name.split(',')[0],
        postalCode: this.getContextText('postcod'),
        state: this.getContextText('region'),
        latitude: this.vendorSelected?.address?.latitude || location.center[1],
        longitude:
          this.vendorSelected?.address?.longitude || location.center[0],
        city:
          this.vendorSelected?.address?.city || this.getContextText('place'),
        county:
          this.vendorSelected?.address?.county || country != 'US'
            ? this.getContextText('region')
            : this.getContextText('district'),
        country: country
      },
      logo: this.vendorSelected?.logo || null,
      website,
      processes: [],
      industries: [],
      sustainmentId:
        this.vendorSelected?.sustainmentId ||
        this._userAccountQuery.getValue().sustainmentId,
      isBuyer: this.userRoles().some(
        (x) => x.buyerRole && this.roles.includes(x.userRoleId)
      ),
      isSupplier: this.userRoles().some(
        (x) => x.supplierRole && this.roles.includes(x.userRoleId)
      ),
      isOther: this.userRoles().some(
        (x) => x.otherRole && this.roles.includes(x.userRoleId)
      )
    };

    this.patchVendor.emit({
      vendor,
      roles: this.roles,
      problems: this.problems
    });
  }

  public internationalCompanyChange(event: boolean): void {
    this.isInternationalCompany = event;
  }

  public selectedRoles(roles: number[]): void {
    this.roles = roles;
  }

  public selectProblems(problems: { id: number; text: string }[]): void {
    this.problems = problems;
  }

  public acknowledgeInternationalAccepted(event: boolean): void {
    this.internationalAcknowledgmentAccepted = event;
    this.disableContinue = !event;
  }

  private getContextText(key: string): string {
    const location: IGeocodeFeature = this.form.value.location;
    const info = location.context.find((item) => item.id.indexOf(key) > -1);
    if (!info) {
      this.loggerDatadogService.error(
        `failed to find context key ${key} info for location`,
        { context: location.context, key }
      );
    }
    return info?.text || '';
  }

  private getCountry(): string {
    const location: IGeocodeFeature = this.form.value.location;
    const info = location.context.find(
      (item) => item.id.indexOf('country') > -1
    );
    return info!.short_code.toUpperCase();
  }

  private logVendorStep(): void {
    this.loggerDatadogService.info(
      `Onboarding step ${this.steps[this.currentStep]}`,
      { data: this.vendorInfo, action: 'onboarding' }
    );
  }
}

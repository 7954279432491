import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MapboxService } from 'sustainment-component';
import { IContext, IGeocodeFeature, ILocation } from 'sustainment-models';

@Component({
  selector: 'app-registration-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class RegistrationCompanyDetailsComponent
  implements OnInit, AfterViewInit
{
  @Input() public form: UntypedFormGroup;
  @Input() public preLoadedAddress: string | undefined;
  @Output() public enableContinue = new EventEmitter<boolean>();
  @Output() public internationalCompanyChange = new EventEmitter<boolean>();
  @Output() public acknowledgeInternationalCompanyAcceptedChange =
    new EventEmitter<boolean>();

  public location: IGeocodeFeature;
  public searchResults$: Observable<IGeocodeFeature[]>;
  public isInternationalCompany: boolean;
  public acknowledgeInternationalCompanyAccepted: boolean;
  private _locationSearch = new Subject<string>();

  public constructor(private _mapSvc: MapboxService) {}

  public ngOnInit(): void {
    this.location = this.form.value.location;
    this.onLocationChanged(this.location);
    this.enableContinue.next(this.form.valid);

    const proximity: ILocation = {
      // Sustainment US address as proximity
      lat: 35.46800452928232,
      lng: -97.52380002546367,
    };

    this.searchResults$ = this._locationSearch.pipe(
      switchMap((location) =>
        this._mapSvc
          .getGeocodingWithType(location, 'address', true, proximity)
          .pipe(
            map((r) => {
              // if there is a preloaded address, set the location
              if (this.preLoadedAddress && r?.features?.length > 0) {
                this.onLocationChanged(r?.features[0]);
                this.location = r?.features[0];
              }

              return r?.features
                .sort((a, b) => {
                  const aIncludesUS = a.place_name.includes('United States');
                  const bIncludesUS = b.place_name.includes('United States');

                  if (aIncludesUS && !bIncludesUS) {
                    return -1;
                  } else if (!aIncludesUS && bIncludesUS) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((item) => {
                  return {
                    ...item,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    place_name: item.place_name.split(', United States')[0],
                  };
                });
            })
          )
      )
    );

    this.form.valueChanges.subscribe(() => {
      this.enableContinue.next(this.form.valid);
    });
  }

  public ngAfterViewInit(): void {
    // if preLoadedAddress is provided, search for it and set location
    if (this.preLoadedAddress) {
      this.searchLocation(this.preLoadedAddress);
    }
  }

  public searchLocation(location: string): void {
    this._locationSearch.next(location);
  }

  private getContextText(
    key: string,
    location: IGeocodeFeature
  ): IContext | undefined {
    return location?.context?.find((item) => item?.id?.indexOf(key) > -1);
  }

  public onLocationChanged(event: IGeocodeFeature): void {
    if (!event) {
      this.isInternationalCompany = false;
      this.internationalCompanyChange.emit(this.isInternationalCompany);
      return;
    }

    this.isInternationalCompany =
      this.getContextText('country', event)?.short_code?.toUpperCase() != 'US';
    this.internationalCompanyChange.emit(this.isInternationalCompany);

    if (this.isInternationalCompany) {
      this.acknowledgeInternationalCompanyAccepted = false;
      this.acknowledgeInternationalCompanyAcceptedChange.emit(false);
    }

    this.form.patchValue({ location: event });
    this.enableContinue.next(this.form.valid);
  }

  public acknowledgmentChange(): void {
    this.acknowledgeInternationalCompanyAccepted =
      !this.acknowledgeInternationalCompanyAccepted;
    this.acknowledgeInternationalCompanyAcceptedChange.emit(
      this.acknowledgeInternationalCompanyAccepted
    );
  }
}

<div class="welcome-container">
  <div class="welcome-box">
    <div class="title">Welcome aboard!</div>
    <div class="success-icon"></div>
    <div class="text">
      <ng-container *ngIf="isReLogin; else confirmationEmail">
        We sent a request to <b>{{ companyName }}</b
        >. When approved you will receive an email.
      </ng-container>
      <ng-template #confirmationEmail>
        We sent you a verification e-mail,<br />
        please use the link to connect.
      </ng-template>
    </div>
    <div class="footer" *ngIf="isReLogin; else confirmationFooter">
      <div class="hint">You can now close this window</div>
    </div>
    <ng-template #confirmationFooter>
      <div class="footer">
        <div class="col-left">
          <a href="javascript:void()">Re-send email</a>
        </div>
        <div class="col-right">
          <s-button type="primary" label="Open e-mail"></s-button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="steps">
  <div class="step login checked"><span class="pi pi-check"></span></div>
  <div class="separator"></div>
  <div
    class="step details"
    [ngClass]="{
      active:
        currentStep === steps.CompanyDetails ||
        currentStep === steps.CompanySuggestions,
      checked:
        currentStep === steps.CompanyTypeRegistration ||
        currentStep === steps.UserProblems
    }"
  >
    <span
      class="text"
      *ngIf="
        currentStep === steps.CompanyDetails ||
        currentStep === steps.CompanySuggestions
      "
      >2</span
    >
    <span
      class="pi pi-check"
      *ngIf="
        currentStep === steps.CompanyTypeRegistration ||
        currentStep === steps.UserProblems
      "
    ></span>
  </div>
  <div class="separator"></div>
  <div
    class="step company-type"
    [ngClass]="{
      active: currentStep === steps.CompanyTypeRegistration,
      checked: currentStep === steps.UserProblems
    }"
  >
    <span
      class="text"
      *ngIf="currentStep !== steps.UserProblems"
      >3</span
    >
    <span
      class="pi pi-check"
      *ngIf="currentStep === steps.UserProblems"
    ></span>
  </div>
  <div class="separator"></div>
  <div
    class="step user-problem"
    [ngClass]="{
      active: currentStep === steps.UserProblems
    }"
  >
    <span class="text">4</span>
  </div>
</div>

<app-registration-company-details
  *ngIf="currentStep === steps.CompanyDetails"
  (enableContinue)="enableContinue($event)"
  [form]="form"
  (internationalCompanyChange)="internationalCompanyChange($event)"
  (acknowledgeInternationalCompanyAcceptedChange)="
    acknowledgeInternationalAccepted($event)
  "
  [preLoadedAddress]="vendorInfo?.primaryAddress"
></app-registration-company-details>
<app-registration-company-suggestion
  *ngIf="currentStep === steps.CompanySuggestions"
  [suggestedVendors]="suggestedVendors"
  (enableContinue)="enableContinue($event)"
></app-registration-company-suggestion>
<app-registration-company-type
  *ngIf="currentStep === steps.CompanyTypeRegistration"
  [roles]="userRoles()"
  (roleSelected)="selectedRoles($event)"
  (enableContinue)="enableContinue($event)"
></app-registration-company-type>
<app-user-problems
  [problems]="selectedProblems"
  (problemSelected)="selectProblems($event)"
  (enableContinue)="enableContinue($event)"
  *ngIf="currentStep === steps.UserProblems"
></app-user-problems>

<div class="registration-section-footer margin-top-32">
  <div class="col-left">
    <a
      *ngIf="currentStep === steps.CompanyDetails"
      href="javascript:void(0)"
      (click)="logout.next()"
      >Sign Out</a
    >
    <a
      *ngIf="currentStep > 0"
      href="javascript:void(0)"
      (click)="onClickBack()"
      >Back</a
    >
  </div>
  <div class="col-right">
    <ng-container *ngIf="currentStep === steps.CompanySuggestions">
      <s-button
        type="tertiary"
        label="My company is not here"
        (click)="onNoneClicked()"
      ></s-button
      >&nbsp;
      <s-button
        type="primary"
        (click)="onContinueClick()"
        class="primary"
        [disabled]="
          disableContinue || !vendorSelected || loadCompaniesSuggestion
        "
        [icon]="loadCompaniesSuggestion ? 'pi pi-spin pi-spinner' : ''"
        iconPos="right"
        [label]="
          vendorSelected?.isRegistered ? 'Join company' : 'Claim profile'
        "
      ></s-button>
    </ng-container>
    <s-button
      type="primary"
      *ngIf="currentStep !== steps.CompanySuggestions"
      (click)="onContinueClick()"
      [disabled]="disableContinue"
      [label]="'Continue'"
    ></s-button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationComponent } from 'src/app/components/features/registration/registration.component';
import { RegistrationCompanyDetailsComponent } from 'src/app/components/features/registration/company-details/company-details.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationCompanySuggestionComponent } from 'src/app/components/features/registration/company-suggestion/company-suggestion.component';
import {
  AsciiOnlyDirective,
  CardsModule,
  SuggestionVendorCardModule
} from 'sustainment-component';
import {
  SustainmentLocationModule,
  SustainmentButtonModule,
  SelectProcessesSubprocessesModule,
  SelectCapabilityListModule
} from 'sustainment-component';
import { RegistrationCompanyProcessesComponent } from 'src/app/components/features/registration/company-processes/company-processes.component';
import { RegistrationCompanyIndustriesComponent } from 'src/app/components/features/registration/company-industries/company-industries.component';
import { RegistrationContainerComponent } from 'src/app/containers/registration/registration.container';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { RegistrationCompanyTypeComponent } from 'src/app/components/features/registration/company-type/company-type.component';
import { CompanyPreselectionComponent } from 'src/app/components/features/registration/company-preselection/company-preselection.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ClaimedWelcomeScreenComponent } from 'src/app/components/features/registration/claimed-welcome-screen/claimed-welcome-screen.component';
import { WelcomeScreenComponent } from 'src/app/components/features/registration/welcome-screen/welcome-screen.component';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { UserProblemsComponent } from '../../components/features/registration/user-problems/user-problems.component';

@NgModule({
  declarations: [
    RegistrationContainerComponent,
    RegistrationComponent,
    RegistrationCompanyDetailsComponent,
    RegistrationCompanySuggestionComponent,
    RegistrationCompanyProcessesComponent,
    RegistrationCompanyIndustriesComponent,
    RegistrationCompanyTypeComponent,
    CompanyPreselectionComponent,
    ClaimedWelcomeScreenComponent,
    WelcomeScreenComponent
  ],
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    CardsModule,
    AutoCompleteModule,
    CheckboxModule,
    ButtonModule,
    SustainmentLocationModule,
    ConfirmDialogModule,
    SustainmentButtonModule,
    SelectProcessesSubprocessesModule,
    SelectCapabilityListModule,
    MultiSelectModule,
    SuggestionVendorCardModule,
    TooltipModule,
    RouterModule,
    UserProblemsComponent,
    AsciiOnlyDirective
  ],
  exports: [
    RegistrationComponent,
    RegistrationContainerComponent,
    RegistrationCompanyDetailsComponent,
    RegistrationCompanySuggestionComponent,
    RegistrationCompanyProcessesComponent,
    RegistrationCompanyIndustriesComponent,
    RegistrationCompanyTypeComponent,
    ClaimedWelcomeScreenComponent,
    WelcomeScreenComponent
  ]
})
export class RegistrationModule {}

<div class="company-type">
  <div class="grid">
    <div class="col-12">
      <div class="registration-title">What's your role?</div>
    </div>
  </div>
  <div class="row registration-sub-title company-type-sub-title">
    <div class="col-12">
      Please check all that apply to help us customize your onboarding
      experience
    </div>
  </div>
  <div class="type-container">
    @for (role of roles; track $index) {
      <div
        class="type-item"
        [ngClass]="{ 'type-selected': selectedRoles[role.userRoleId] }"
        (click)="onCompanyBuyerChanged($event, role.userRoleId)"
      >
        <div class="type-item-body">
          <div class="type-item-title">
            {{ role.name }}
            <div class="type-item-check">
              <p-checkbox
                [ngModel]="selectedRoles[role.userRoleId]"
                [binary]="true"
                [readonly]="true"
                #checkbox
              ></p-checkbox>
            </div>
          </div>
          <div class="type-item-subtitle">{{ role.description }}</div>
        </div>
      </div>
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { StateStore } from './state.store';
import { StateAPI } from 'src/app/api/state.api';
import { IStateData } from './state.model';

@Injectable({ providedIn: 'root' })
export class StateActions {
  public constructor(private stateAPI: StateAPI, private store: StateStore) {}

  public getStateData(): void {
    if (!this.store.getValue()?.states?.length) {
      this.stateAPI.getStateData().subscribe((states: IStateData[]) =>
        this.store.update(() => ({
          states,
        }))
      );
    }
  }
}

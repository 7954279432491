<div class="welcome-container">
  <div class="welcome-icon"><span class="pi pi-check-circle"></span></div>
  <div class="text">
    <div class="welcome-title">You’re in! Let’s get started!</div>
    <div class="welcome-subtitle">
      Enhance your experience by filling out your company profile, and unlocking
      opportunities that align perfectly with your company.
    </div>
  </div>
  <div class="welcome-actions">
    <s-button
      type="primary"
      [label]="'Continue to company profile'"
      (click)="continueToProfile()"
    ></s-button>
    <s-button
      type="tertiary"
      [label]="'I\'ll do it later'"
      (click)="continueToHp()"
    ></s-button>
  </div>
</div>

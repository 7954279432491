<div class="company-suggestion">
  <div class="grid">
    <div class="col-12">
      <span class="registration-title">We think we’ve found a match.</span>
    </div>
  </div>
  <div class="grid registration-sub-title">
    <div class="col-12">
      Your company may already have a profile. Select an existing profile from
      the list below to request to join an existing account or claim a profile.
    </div>
  </div>
  <div class="vendors-container">
    <div
      *ngFor="let result of suggestedVendors; let i = index"
      class="vendor-cards"
    >
      <suggestion-vendor-card
        [vendor]="result"
        [isClaimed]="!!result.isRegistered"
        (toggleCardSelection)="onToggleCardSelection($event)"
      ></suggestion-vendor-card>
    </div>
  </div>
</div>

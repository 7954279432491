import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  BaseOktaApi,
  OktaService,
  VendorFormatService,
} from 'sustainment-component';
import {
  OpensearchOnboardingRequest,
  SupplierListModel,
} from 'sustainment-models';

@Injectable()
export class OnboardingAPI extends BaseOktaApi {
  protected urlBase: string;
  public constructor(
    httpClient: HttpClient,
    oktaService: OktaService,
    private formatService: VendorFormatService
  ) {
    super(httpClient, oktaService);
    this.urlBase = environment.searchMiddlewareApiBaseUrl;
  }

  public getOpensearchResults(
    body: OpensearchOnboardingRequest
  ): Observable<SupplierListModel[]> {
    return this.post<SupplierListModel[]>(
      `/opensearch/onboarding-vendors`,
      body
    ).pipe(
      tap((x) =>
        x.forEach(
          (y) =>
            (y.logoUrl = this.formatService.getLogo(
              y.sustainmentId,
              y.logo || ''
            ))
        )
      )
    );
  }

  public getOpensearchVendor(
    sustainmentId: string
  ): Observable<SupplierListModel> {
    return this.post<SupplierListModel>(
      `/opensearch/onboarding-vendor/${sustainmentId}`,
      {}
    ).pipe(
      tap(
        (y) =>
          (y.logoUrl = this.formatService.getLogo(
            y.sustainmentId,
            y.logo || ''
          ))
      )
    );
  }
}

<div
  class="company-detail"
  [formGroup]="form"
>
  <div class="row">
    <div class="col-12">
      <span class="registration-title">Company Details</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="registration-sub-title">
        Enter your company details to create a profile.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Company Name *</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          id="name"
          type="text"
          pInputText
          placeholder="Enter company name"
          formControlName="name"
          maxlength="75"
          asciiOnly
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Primary Facility Address *</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <s-location
          [searchResults]="(searchResults$ | async) || []"
          (searchLocation)="searchLocation($event)"
          [(location)]="location"
          placeholder="Enter your primary facility address"
          [isRequired]="true"
          [showRequiredMessage]="false"
          (locationChange)="onLocationChanged($event)"
        ></s-location>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="registration-label">Website</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          id="website"
          type="text"
          pInputText
          placeholder="Enter the company website"
          formControlName="website"
          maxlength="75"
        />
      </div>
    </div>
  </div>
  <div class="row registration-comments">
    <div class="col-12">
      <span>* indicates required field</span>
    </div>
  </div>
  <div class="row row-international-company">
    <div class="international-manufacturer">
      <p-checkbox
        id="internationalManufacturer"
        name="internationalManufacturer"
        [binary]="true"
        (onChange)="acknowledgmentChange()"
        [disabled]="!isInternationalCompany"
        [pTooltip]="
          isInternationalCompany
            ? 'This restriction only applies to international companies.'
            : ''
        "
      >
      </p-checkbox>

      <div class="label-international">
        <label for="internationalManufacturer"
          >I’m a manufacturer who doesn’t operate in the United States. I
          understand the
          <a
            href="https://www.sustainment.com/community-guidelines"
            target="_blank"
            ><b><u>restrictions</u></b></a
          >
          that apply to my account.
        </label>
      </div>
    </div>
  </div>
</div>

<div class="company-suggestion">
  <div class="row">
    <div class="col-12">
      <span class="registration-title">We think we’ve found a match.</span>
    </div>
  </div>
  <div class="row registration-sub-title">
    <div class="col-12">
      <span
        >Your company may already have a profile. Select an existing profile
        from the list below to request to join an existing account or claim a
        profile.</span
      >
    </div>
  </div>
  <div class="cards selected">
    <suggestion-vendor-card
      [vendor]="vendor"
      [isClaimed]="!!vendor.isRegistered"
      (toggleCardSelection)="onToggleCardSelection($event)"
    ></suggestion-vendor-card>
  </div>
  <div class="registration-section-footer margin-top-32">
    <div class="col-left">
      <s-button
        type="tertiary"
        label="My company is not here"
        (click)="onCancelClick()"
      ></s-button>
    </div>
    <div class="col-right">
      <s-button
        type="primary"
        label="Join company"
        (click)="onSelectClick()"
      ></s-button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStateData } from '../store/state/state.model';
import { BaseVendorAPI } from 'sustainment-component';

@Injectable()
export class StateAPI extends BaseVendorAPI {
  private _relativeUrl = 'state';

  public getStateData(): Observable<IStateData[]> {
    return this.get<IStateData[]>(this._relativeUrl);
  }
}

import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { UserProblem } from 'sustainment-component';

@Component({
  selector: 'app-user-problems',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule],
  templateUrl: './user-problems.component.html',
  styleUrl: './user-problems.component.scss'
})
export class UserProblemsComponent {
  public problems = input.required<UserProblem[]>();
  public enableContinue = output<boolean>();
  public problemSelected = output<{ id: number; text: string }[]>();

  public selectedProblems: Record<number, { selected: boolean; text: string }> =
    {};

  public onCompanyBuyerChanged(event: Event, roleId: number): void {
    event.stopPropagation();
    if (this.selectedProblems[roleId]) {
      this.selectedProblems[roleId].selected =
        !this.selectedProblems[roleId].selected;
    } else {
      const problem = this.problems().find((x) => x.userProblemId === roleId);
      this.selectedProblems[roleId] = {
        selected: true,
        text: problem?.description ? problem?.name || '' : ''
      };
    }
    const selectedRoles = Object.entries(this.selectedProblems)
      .filter(([, value]) => value.selected === true)
      .map(([key, value]) => ({ id: +key, text: value.text }));
    this.enableContinue.emit(!!selectedRoles.length);
    this.problemSelected.emit(selectedRoles);
  }

  public textChange(): void {
    const selectedRoles = Object.entries(this.selectedProblems)
      .filter(([, value]) => value.selected === true)
      .map(([key, value]) => ({ id: +key, text: value.text }));
    this.problemSelected.emit(selectedRoles);
  }
}
